import Api from '@/services/Api'

export default {
  findAll (pagination) {
    return Api().post('condominios', pagination)
  },
  insert (condominio) {
    return Api().post('condominio', condominio)
  },
  update (condominio) {
    return Api().put('condominio', condominio)
  },
  delete (condominio) {
    return Api().delete(`condominio?id=${condominio.id}`, condominio)
  },
  findById (condominio) {
    const url = `condominio/${condominio.id}`
    return Api().get(url)
  },
  findByParam (param, pagination) {
    param = {
      ...param, pagination
    }
    return Api().get('condominioParam', param)
  }
}
