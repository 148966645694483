<template>
  <crud-form
    :titulo-pagina="tituloPagina"
    :is-editting="isEditting"
    :mensagem-pergunta-remocao="mensagemPerguntaRemocao"
    @deleteEvent="remover"
    @closeDialogEvent="closeDialog"
    @saveEvent="saveEventHandler"
    @save-continue-event="saveContinueEvent"
  >
    <v-container class="py-0">
      <v-row>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="objEditar.nomeCondominio"
            class="purple-input"
            label="Nome Condomínio"
          />
        </v-col>
        <v-col
          cols="6"
        >
          <v-text-field
            v-model="objEditar.blocos"
            label="Blocos"
            class="purple-input"
            mask="######"
          />
        </v-col>
        <v-col
          cols="6"
        >
          <v-text-field
            v-model="objEditar.totalApartamentos"
            label="Total Apartamentos"
            class="purple-input"
            mask="######"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="objEditar.diaVencimento"
            :items="diasVencimentos"
            prepend-icon="mdi-calendar-alert"
            label="Dia do Vencimento"
          />
        </v-col>
        <v-col
          v-if="isEditting"
          cols="12"
        >
          <v-text-field
            v-model="objEditar.sindico"
            prepend-icon="mdi-account-star"
            label="Síndico"
            readonly
          />
        </v-col>
        <v-col cols="12">
          <v-combobox
            v-model="listaApartamentosArr"
            label="Listagem de Apartamentos"
            chips
            clearable
            prepend-icon="mdi-filter-variant"
            multiple
          >
            <template
              slot="selection"
              slot-scope="data"
            >
              <v-chip
                :selected="data.selected"
                label
                small
                dark
                close
                @input="removeApartamento(data.item)"
              >
                <span>{{ data.item }}</span>
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
    </v-container>
  </crud-form>
</template>

<script>

  import CondominiosService from '@/services/CondominiosService'

  export default {
    data: () => ({
      tituloPagina: 'Condomínios',
      mensagemPerguntaRemocao: 'Deseja remover este condomínio?',
      objPadrao: {
        nomeCondominio: '',
        blocos: 0,
        totalApartamentos: 0,
        diaVencimento: 1,
        sindico: '',
        listaApartamentos: ''
      },
      objEditar: {
        nomeCondominio: '',
        blocos: 0,
        totalApartamentos: 0,
        diaVencimento: 1,
        sindico: '',
        listaApartamentos: ''
      },
      diasVencimentos: [1, 5, 7, 10, 15],
      isEditting: true,
      listaApartamentosArr: []
    }),
    created () {
      let id = this.$route.params.id
      if (id) {
        this.editar(id)
      } else {
        this.isEditting = false
        this.objEditar = this.objPadrao
      }
    },
    methods: {
      removeApartamento (index) {
        this.listaApartamentosArr.splice(index, 1)
      },
      saveContinueEvent () {
        this.inserir(this.objEditar, true)
      },
      saveEventHandler () {
        this.objEditar.listaApartamentos = JSON.stringify(this.listaApartamentosArr)
        if (this.isEditting) {
          this.atualizar(this.objEditar)
        } else {
          this.inserir(this.objEditar)
        }
      },
      atualizar (condominio) {
        try {
          CondominiosService.update(condominio)
            .then(response => {
              if (!response) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
                })
              } else if (response.error) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: '' + response.error
                })
              } else {
                this.$root.$emit('mostra_dialogo_info', {
                  dialogoTitulo: 'Sucesso',
                  dialogoDescricao: 'Condomínio salvo com sucesso'
                })
                this.closeDialog()
              }
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Não foi possível concluir a atualização, tente mais tarde\n' + error
          })
        }
      },
      closeDialog () {
        this.$router.push('/condominios')
      },
      remover () {
        const condominio = this.objEditar
        try {
          CondominiosService.delete(condominio)
            .then(response => {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: 'Sucesso',
                dialogoDescricao: 'Condomínio removido com sucesso'
              })
              this.closeDialog()
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao remover um condomínio:\n ->' + error
          })
        }
      },
      inserir (condominio, manterNestaTela) {
        try {
          CondominiosService.insert(condominio).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: 'Sucesso',
                dialogoDescricao: 'Condominio salvo com sucesso'
              })
              if (manterNestaTela) {
                this.editar(condominio.data.id)
              } else {
                this.closeDialog()
              }
            }
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao inserir um condomínio:\n ->' + error
          })
        }
      },
      converteStrParaListaApartamentosArr () {
        if (this.objEditar.listaApartamentos) {
          this.listaApartamentosArr = JSON.parse(this.objEditar.listaApartamentos)
        } else {
          this.objEditar.listaApartamentos = []
        }
      },
      editar (id) {
        CondominiosService.findById({ id })
          .then(condominio => {
            if (condominio.id) {
              this.objEditar = condominio
              this.converteStrParaListaApartamentosArr()
              this.isEditting = true
            } else if (condominio.data.id) {
              this.objEditar = condominio.data
              this.converteStrParaListaApartamentosArr()
              this.isEditting = true
            } else {
              this.isEditting = false
            }
          })
          .catch(() => {
            this.isEditting = false
          })
      }
    }
  }
</script>
